import React from "react";
import "./Footer.css";
import { Footer } from 'flowbite-react'
function FooterPage() {
  return (
    <Footer className="footer">
    <div className="footer-logo">
        <div className="autocar">
        <span>Auto</span>
        <span>Car</span>
        </div>
        <p>Lorem ipsumdolor sit amet, consectetur adipiscing elit. Nunc
        vulputatelibero et velit interdum, ac aliquet odio mattis. Class aptent</p>
    </div>
    <div className="footer-list">
        <Footer.LinkGroup>
        <Footer.Link href="/">
            New cars
        </Footer.Link>
        <Footer.Link href="/used">
            User cars
        </Footer.Link>
        <Footer.Link href="/about">
            About us
        </Footer.Link>
        <Footer.Link href="/contact">
            Contact
        </Footer.Link>
        </Footer.LinkGroup>
    </div>
    </Footer>
  );
}

export default FooterPage;
