import React, { useState, useEffect } from "react";
import '../Styles/FilterComponent.css';
import SubFilterComponent from "./SubFilterComponent";
import { Dropdown } from "flowbite-react";
function FilterComponent(props) {
    const [ selectedYearFrom, setSelectedYearFrom ] = useState('')
    const [ selectedPriceFrom, setSelectedPriceFrom ] = useState('')
    const [ selectedMilageFrom, setSelectedMilageFrom ] = useState('')
    const [ selectedYearTill, setSelectedYearTill ] = useState('')
    const [ selectedPriceTill, setSelectedPriceTill ] = useState('')
    const [ selectedMilageTill, setSelectedMilageTill ] = useState('')
    const [selectedColor, setSelectedColor] = useState('')
    useEffect(() => {
        const {
            handleFromYearSelect,
            handleTillYearSelect,
            handleFromPriceSelect,
            handleTillPriceSelect,
            handleFromMilageSelect,
            handleTillMilageSelect,
          } = props;

        if (selectedYearFrom) handleFromYearSelect(selectedYearFrom);
        if (selectedYearTill) handleTillYearSelect(selectedYearTill);
        if (selectedPriceFrom) handleFromPriceSelect(selectedPriceFrom);
        if (selectedPriceTill) handleTillPriceSelect(selectedPriceTill);
        if (selectedMilageFrom) handleFromMilageSelect(selectedMilageFrom);
        if (selectedMilageTill) handleTillMilageSelect(selectedMilageTill);
    }, [
        selectedYearFrom,
        selectedYearTill,
        selectedPriceFrom,
        selectedPriceTill,
        selectedMilageFrom,
        selectedMilageTill,
        props, // Add props to the dependency array
    ]);
    const handleColorSelect = (color) => {
        setSelectedColor(color);
        props.handleColorSelect(color)
      };
    return (
        <div className="main">
            <div className="filter">
                <p>Filters</p>
            </div>
            <SubFilterComponent
                label="Price"
                from={props.priceFrom}
                till={props.priceTill}
                setSelectedFrom = { setSelectedPriceFrom }
                setSelectedTill = { setSelectedPriceTill }
            />
            
            <SubFilterComponent
                label="Year"
                from={props.yearFrom}
                till={props.yearTill}
                setSelectedFrom = { setSelectedYearFrom }
                setSelectedTill = { setSelectedYearTill }
            />

            <SubFilterComponent
                label="Milage"
                from={props.milageFrom}
                till={props.milageTill}
                setSelectedFrom = { setSelectedMilageFrom }
                setSelectedTill = { setSelectedMilageTill }
            />

            <div className="filter-sort">
                <p>Color</p>
                <div className="list-group">
                    <Dropdown label= {selectedColor ? selectedColor : "Select a color"} className="list" value={selectedColor} onChange={props.handleColorSelect}>
                        {props.color.map((each, index) => (
                            <Dropdown.Item key={index} onClick={() => handleColorSelect(each)}>{each}</Dropdown.Item>
                        ))}
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};

export default FilterComponent;
