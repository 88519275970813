import React from "react";
import '../Styles/CarInformation.css';
function CarInformation(props) {
  return (
    <div className="subCarInfo">
        <p className="category">{ props.category }</p>
        <p className="info">{ props.info }</p>
    </div>
  );
};

export default CarInformation;