import React from "react";
import '../Styles/SelectedCar.css';
import { Button } from "flowbite-react";
import CarInformation from "./CarInformation";
function SelectedCar(props) {
  return (
    <div className="main-body">
        <div className="picture"></div>
        <div className="display-detail">
            <p className="car-name">{props.make}</p>
            <div className="display-body">
                <div className="milage_intake">
                    <CarInformation category="milage" info={props.milage + ' km'} />
                    <CarInformation category="intake" info={props.intake} />
                </div>
                <div className="transmission_power">
                    <CarInformation category="Transmission" info={props.transmission} />
                    <CarInformation category="Power KW (PK)" info={props.power} />
                </div>
                <div className="year_color">
                    <CarInformation category="Year" info={props.year} />
                    <CarInformation category="Color" info={props.color} />
                </div>
                <div className="view_car">
                    <Button gradientDuoTone="pinkToOrange" outline>
                        view car
                    </Button>
                </div>
            </div>
        </div>
    </div>
  );
};

export default SelectedCar;