import React, { useState } from "react";
import { Dropdown } from "flowbite-react";
import '../Styles/SubFilterComponent.css';

function SubFilterComponent(props) {
    const [fromLabel, setFromLabel] = useState('');
    const [tillLabel, setTillLabel] = useState('');
    const [itemFromClicked, setItemFromClicked] = useState(false)
    const [itemTillClicked, setItemTillClicked] = useState(false)
    const handleFromClick = (selecteditem) => {
        setFromLabel(selecteditem)
        props.setSelectedFrom(selecteditem)
        setItemFromClicked(true)
    }
    const handleTillClick = (selecteditem) => {
        setTillLabel(selecteditem)
        props.setSelectedTill(selecteditem)
        setItemTillClicked(true)
    }
    return (
        <div className="filter-sort">
            <p>{props.label}</p>
            <div className="list-group">
                <Dropdown label={itemFromClicked ? fromLabel : "from"} className="list" value={ props.selectedFrom } onChange={ props.setSelectedFrom }>
                    {props.from.map((from, index) => (
                        <Dropdown.Item className = "item" key = {index} value = {from} onClick={() => handleFromClick(from)}>{from}</Dropdown.Item>
                    ))}
                </Dropdown>
                <Dropdown label={itemTillClicked ? tillLabel : "till"} className="list" value={ props.selectedFrom } onChange={ props.setSelectedTill }>
                    {props.till.map((till, index) => (
                        <Dropdown.Item className = "item" key = {index} value = {till} onClick={() => handleTillClick(till)}>{till}</Dropdown.Item>
                    ))}
                </Dropdown>
            </div>
        </div>
    );
};
export default SubFilterComponent;