import "./App.css";
import NavBar from "./components/NavBar";
import FooterPage from "./components/FooterPage";
import { BrowserRouter as Router,  Route, Routes } from "react-router-dom";
import { NewCar } from "./components/Pages/NewCar";
import { About } from "./components/Pages/About";
import { Used } from "./components/Pages/Used";
import { Contact } from "./components/Pages/Contact";

function App() {
  return (
    <>
      <Router>
        <NavBar />
        <div className="pages">
          <Routes>
            <Route path="/" element={<NewCar />} />
            <Route path="/about" element={<About />} />
            <Route path="/used" element={<Used />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <FooterPage />
      </Router>
  </>
  );
}

export default App;
