import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import { Button, Dropdown, Avatar } from "flowbite-react";
function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <span>Auto</span>
            <span>Car</span>
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                New cars
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/used"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Used cars
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact
              </NavLink>
            </li>
          </ul>
          
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
        <div className="avatar">
          <Button gradientMonochrome="failure">
            Find your car
          </Button>
          <div className="flex md:order-2 user-picture">
            <Dropdown
              inline
              label={<Avatar alt="User settings" img="https://flowbite.com/docs/images/people/profile-picture-5.jpg" rounded/>}
            >
              <Dropdown.Header>
                <span className="block text-sm">
                  Viet Anh Tran
                </span>
                <span className="block truncate text-sm font-medium">
                  topbrightdev@gmail.com
                </span>
              </Dropdown.Header>
              <Dropdown.Item>
                Dashboard
              </Dropdown.Item>
              <Dropdown.Item>
                Settings
              </Dropdown.Item>
              <Dropdown.Item>
                Earnings
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item>
                Sign out
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
