import React, { useState, useEffect } from "react";
import '../Styles/Used.css';
import { Pagination } from 'flowbite-react';
import CarDetail from "../components/CarDetail";

let years = [], show_milages = [], minMilage = 0, show_prices = [], minPrice = 0, show_colors = []
export const Used = () => {

  const [carData, setCarData] = useState([]);

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const response = await fetch('https://site--cars-express-app--gbmmwqlm6cfw.code.run/cars');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      years = Array.from(new Set(data.data.map(car => car.year)));
      const milages = Array.from(new Set(data.data.map(car => car.kilometer)));
      const prices = Array.from(new Set(data.data.map(car => car.price)));
      show_colors = Array.from(new Set(data.data.map(car => car.color)));
      years.sort((a, b) => a - b);
      milages.sort((a, b) => a - b);
      prices.sort((a, b) => a - b);
      show_colors.sort();
      const milage_index = Math.floor(Math.min(...milages) / 1000)
      // const price_index = Math.floor(Math.min(...prices) / 1000)
      const powerOf10 = Math.floor(Math.log10(Math.min(...prices)));
      console.log(powerOf10)
      minPrice = Math.pow(10, powerOf10)
      console.log(minPrice)
      minMilage = milage_index * 1000
      
      for (let j = minPrice; j < Math.max(...prices); j += 500000)
        show_prices.push(j)
      for (let i = minMilage; i < Math.max(...milages); i += 10000)
        show_milages.push(i)
      console.log(show_prices)
      
      setCarData(data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage;
  const paginatedCars = carData.slice(startIndex, endIndex);
  
  return (
    <div>
      <div className="detail-body">
        <div className="display">
          {
            paginatedCars.map((car, index) => (
            <CarDetail
              no={(index + 1) + 10 * (currentPage - 1)}
              make={car.make}
              drivetrain={car.kilometer}
              model={car.model}
              power={car.max_power}
              key={index}
            />
            ))
          }
          <Pagination
            currentPage={currentPage}
            layout="pagination"
            nextLabel="Next"
            onPageChange={page => {
              setCurrentPage(page);
            }}
            previousLabel="Previous"
            showIcons
            totalPages={Math.ceil(carData.length / itemsPerPage)}
          />
        </div>
      </div>
    </div>
  );
};
