import React from "react";
import '../Styles/CarDetail.css';
import CarInformation from "./CarInformation";
function CarDetail(props) {
  return (
    <div className="main-body">
        <div className="picture"></div>
        <div className="display-detail">
            <p className="car-name">{props.no}</p>
            <div className="display-body">
                <div className="milage_intake">
                    <CarInformation category="Car Id" info={props.drivetrain} />
                    <CarInformation category="Make" info={props.make} />
                </div>
                <div className="transmission_power">
                    <CarInformation category="Modal" info={props.model} />
                    <CarInformation category="Power KW (PK)" info={props.power} />
                </div>
            </div>
        </div>
    </div>
  );
};

export default CarDetail;