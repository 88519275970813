import React, { useState, useEffect } from "react";
import '../Styles/newCar.css';
import FilterComponent from "../components/FilterComponent";
import SelectedCar from "../components/SelectedCar";
import { Pagination } from 'flowbite-react';

let years = [], show_milages = [], minMilage = 0, show_prices = [], minPrice = 0, show_colors = []
export const NewCar = () => {
  const [selectedColor, setSelectedColor] = useState('');
  const [fromPrice, setFromPrice] = useState(0);
  const [tillPrice, setTillPrice] = useState(20000000);
  const [fromYear, setFromYear] = useState(0);
  const [tillYear, setTillYear] = useState(2023);
  const [fromMilage, setFromMilage] = useState(0);
  const [tillMilage, setTillMilage] = useState(1000000);
  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };
  const handleFromPriceSelect = (color) => {
    setFromPrice(color);
  };
  const handleTillPriceSelect = (color) => {
    setTillPrice(color);
  };
  const handleFromYearSelect = (color) => {
    setFromYear(color);
  };
  const handleTillYearSelect = (color) => {
    setTillYear(color);
  };
  const handleFromMilageSelect = (color) => {
    setFromMilage(color);
  };
  const handleTillMilageSelect = (color) => {
    setTillMilage(color);
  };

  const [carData, setCarData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const response = await fetch('https://site--cars-express-app--gbmmwqlm6cfw.code.run/cars');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      years = Array.from(new Set(data.data.map(car => car.year)));
      const milages = Array.from(new Set(data.data.map(car => car.kilometer)));
      const prices = Array.from(new Set(data.data.map(car => car.price)));
      show_colors = Array.from(new Set(data.data.map(car => car.color)));
      years.sort((a, b) => a - b);
      milages.sort((a, b) => a - b);
      prices.sort((a, b) => a - b);
      show_colors.sort();
      const milage_index = Math.floor(Math.min(...milages) / 1000)
      // const price_index = Math.floor(Math.min(...prices) / 1000)
      const powerOf10 = Math.floor(Math.log10(Math.min(...prices)));
      console.log(powerOf10)
      minPrice = Math.pow(10, powerOf10)
      console.log(minPrice)
      minMilage = milage_index * 1000
      
      for (let j = minPrice; j < Math.max(...prices); j += 500000)
        show_prices.push(j)
      for (let i = minMilage; i < Math.max(...milages); i += 10000)
        show_milages.push(i)
      console.log(show_prices)
      
      setCarData(data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage;
  const filteredCars = carData.filter((car) => {
    return (
      (selectedColor
        ? car.year >= fromYear &&
          car.year <= tillYear &&
          car.kilometer >= fromMilage &&
          car.kilometer <= tillMilage &&
          car.price >= fromPrice &&
          car.price <= tillPrice &&
          car.color === selectedColor
        : car.year >= fromYear &&
          car.year <= tillYear &&
          car.kilometer >= fromMilage &&
          car.kilometer <= tillMilage &&
          car.price >= fromPrice &&
          car.price <= tillPrice)
    );
  });
  const paginatedCars = filteredCars.slice(startIndex, endIndex);
  
  return (
    <div>
      <div className="newCarLogo">
        <p>New BMW Cars</p>
        <p>Lorem ipsum dolor sit amet. consectetur adipising elit. Nuc vulputate libero et velit interdum, as aliquest odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis.</p>
      </div>
      <div className="body">
        <div style={{ height: 400 }}>
          <div className="filter">
            <FilterComponent
              priceFrom={show_prices}
              priceTill={show_prices.filter(price => price > fromPrice)}
              yearFrom={years}
              yearTill={years.filter(year => parseInt(year) > fromYear)}
              milageFrom={show_milages}
              milageTill={show_milages.filter(milage => parseInt(milage) > fromMilage)}
              color={show_colors}
              handleColorSelect={handleColorSelect}
              handleFromYearSelect={handleFromYearSelect}
              handleTillYearSelect={handleTillYearSelect}
              handleFromPriceSelect={handleFromPriceSelect}
              handleTillPriceSelect={handleTillPriceSelect}
              handleFromMilageSelect={handleFromMilageSelect}
              handleTillMilageSelect={handleTillMilageSelect}
            />
          </div>
        </div>
        <div className="display">
          {
            paginatedCars.map((car, index) => (
            <SelectedCar
              make={car.make}
              milage={car.kilometer}
              intake={car.fuel_type}
              transmission={car.transmission}
              power={car.max_power}
              year={car.year}
              color={car.color}
              key={index}
            />
            ))
          }
          <Pagination
            currentPage={currentPage}
            layout="pagination"
            nextLabel="Next"
            onPageChange={page => {
              setCurrentPage(page);
            }}
            previousLabel="Previous"
            showIcons
            totalPages={Math.ceil(filteredCars.length / itemsPerPage)}
          />
        </div>

      </div>
    </div>
  );
};
